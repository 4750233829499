import { IconButton, Stack, StackItem } from '@fluentui/react';
import { dataGrid, createScope } from '@harmony/enablers/react';
import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import { PageLayoutContext } from './PageLayout';

const scope = createScope({
  reactInstance: React
})
const DataGrid = scope.forReact(dataGrid);

export const GenericTrackerPage = (params: any) => {
    const { myTrackerList, RemoveQuoteFromTrackerList } = useContext(PageLayoutContext) as any;

    let newTopRows = [
      {id: "Others", expanded: true, cells: {id: ""}},
    ]
    let anyCompleted = false;
    var newBottomRows = params.listOfQuotes?.map((el: any) => {
        if (el?.quoteStatus == "Completed" && !anyCompleted)
          anyCompleted = true;

        const row = {
            id: el?.quoteId,
            parentId: el?.quoteStatus == "Completed" ? "Completed" : "Others",
            cells: {id: el?.quoteId, name: el?.quoteName, status: el?.quoteStatus, transactionModel: el?.transactionModel}
        }
        return row;
    })
    
    if (anyCompleted)
      newTopRows = [newTopRows[0], {id: "Completed", expanded: false, cells: {id: "Completed"}}]
    else {
      newTopRows = []
      newBottomRows.forEach((el: any) => el.parentId = null)
    }
    
    let newRows = [...newTopRows, ...newBottomRows];
    
    const generateListOfItems = (row: any, params: any) => {
      if (params?.hash === "#/business")
      {
        if (params?.userHasAdminRole === true && params?.hash === "#/business")
        {
          let items = [
            {
              key: "delete",
              text: "Remove",
              iconProps: { iconName: "Delete" },
              onClick: () => {params.removeQuoteFromList(row.cells.id)},
            }
          ]

          if (myTrackerList?.findIndex((el: any) => el.quoteId == row.cells.id) != -1) {
            items.push({
              key: "delete",
              text: "Stop tracking",
              iconProps: { iconName: "Delete" },
              onClick: () => {RemoveQuoteFromTrackerList(row.cells.id)},
            });
          } else {
            items.push({
              key: "track",
              text: "Track this quote",
              iconProps: { iconName: "BullseyeTargetEdit" },
              onClick: () => {params.trackFromBusinessList(row.cells.id)},
            });
          }
          return items;
        }
        else
        {
          if (myTrackerList?.findIndex((el: any) => el.quoteId == row.cells.id) != -1) {
            return [{
              key: "delete",
              text: "Stop tracking",
              iconProps: { iconName: "Delete" },
              onClick: () => {RemoveQuoteFromTrackerList(row.cells.id)},
            }]
          } else {
            return [{
              key: "track",
              text: "Track this quote",
              iconProps: { iconName: "BullseyeTargetEdit" },
              onClick: () => {params.trackFromBusinessList(row.cells.id)},
            }]
          }
        }
      }
      else
      {
        return [{
          key: "delete",
          text: "Remove",
          iconProps: { iconName: "Delete" },
          onClick: () => {params.removeQuoteFromList(row.cells.id)},
        }]
      }
    }

    return <Stack>
      <StackItem>
        <DataGrid rows={newRows ?? []} columns={newColumns}>
          {newRows.map((row: any, idx: any) => {
            if (row.id == "Completed" || row.id == "Others")
              return;
            return (<div
            slot={`id-${row.id}`}
            key={`id-${row.id}`}
          >
            <Link target="_blank" to={`/proposal-details/${row.cells.id}`}>{row.cells.id}</Link>
          </div>)
          })}

          {newRows.map((row: any, idx: any) => {
          if (row.id == "Completed" || row.id == "Others")
            return;
          return (
          <div
            slot={`icon-${row.id}`}
            key={`icon-${row.id}`}
          >
            <IconButton   
              menuIconProps={{ iconName: "More" }}
              role="button"
              menuProps={{
                items: generateListOfItems(row, params)}}
              ></IconButton>
          </div>)
          })}
        </DataGrid>
      </StackItem>
      </Stack>;
}

var newColumns = [
  {
      field: `id`,
      content: `Id`,
      sortable: false,
      display: {
          width: '100px',
          maxWidth: '100px'
      }
  },
  {
      field: `name`,
      content: `Name`,
      sortable: false,
      display: {
          width: '300px',
          maxWidth: '600px'
      }
  },
  {
      field: `status`,
      content: `Status`,
      sortable: false,
      display: {
          width: '100px',
          maxWidth: '100px'
      }
  },
  {
    field: `transactionModel`,
    content: `Transaction Model`,
    sortable: false,
    display: {
        width: '75px',
        maxWidth: '75px'
    }
  },
  {
    field: `icon`,
    content: ``,
    sortable: false,
    display: {
        width: '10px',
        maxWidth: '10px'
    }
  }
]
