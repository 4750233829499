import { Stack } from "react-bootstrap";
import { StackItem } from "@fluentui/react";
import { chart, dataGrid, createScope } from '@harmony/enablers/react';
import React, { useEffect } from "react";
import { ChartOption } from "@harmony/enablers/components/chart/types";
import '../index.css';

const scope = createScope({
  reactInstance: React
})
const Chart = scope.forReact(chart);
const DataGrid = scope.forReact(dataGrid);

export const ConfidenceMeter = (param: any) => {

    const [confidenceDetails, setConfidenceDetails] = React.useState(param?.confidenceDetails);
    let items = confidenceDetails?.rules ?? [];
    let riskScore = confidenceDetails?.riskScore;
    let riskLevel = confidenceDetails?.risk;

    const GenerateRows = (data: any) => {
      var categories = data?.map((el: any) => el?.category)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
    
      let isAllRulesPassing = data?.findIndex((el: any) => el?.outcome == "Fail") === -1;
      var categoryRows = categories.map((el: any) => ({id: el, expanded: isAllRulesPassing ? true : data?.filter((el2: any) => el2?.category == el && el2?.outcome == "Fail").length > 0, cells: {id: el}}));
      
      var regularRows = data?.map((el: any) => {
          const row = {
              id: el?.id,
              parentId: el?.category,
              cells: {id: el?.id, description: el?.description, expectedValue: el?.expectedValue, actualValue: el?.actualValue, outcome: el?.outcome}
          }
          return row;
      })
    
      return [...categoryRows, ...regularRows];
    }

    const options = {
      title: {
        text: 'Confidence Meter',
        subtext: riskLevel + ' Risk',
      },
      series: [{
        type: 'gauge',
        data: [
            {
              // name: ?.riskLevel + ' Risk',
              value: 100 - riskScore
            },
        ]
      }],
    } as ChartOption;
    var newRows = GenerateRows(items);

    useEffect(() => {
      setConfidenceDetails(param?.confidenceDetails);
    }, [param?.confidenceDetails]);
    
    useEffect(() => {
      if (riskScore == null || riskScore == undefined || riskLevel == null || riskLevel == undefined)
      {
        return;
      }
      else
      {
        var chart = document.getElementsByClassName("he-chart")[0];
        var shadowRoot = chart.shadowRoot as ShadowRoot;
        var riskText = shadowRoot.querySelector("div.chart__subheading") as Element;
        if (riskText != undefined)
        {
          riskText.innerHTML = `<p style="font-size: 18px;font-weight: 500;margin-top: 2px;">${riskText?.innerHTML}</p>`;
        }  
      }
    }, [confidenceDetails])
 
    return  <Stack style={{marginTop: 10}}>
              <StackItem>
                {/* <img style={{width: '20%', float: 'right', marginTop: 10}} src={GetImageByRiskLevel(.riskLevel)} /> */}
                <Chart  hideFilter options={options} />
              </StackItem>
              <StackItem>
                <DataGrid id={"confidence-meter-datagrid"} rows={newRows} columns={newColumns}>
                {newRows.map((row: any) => (
                  <div
                    style={{background: row.cells.outcome == "Fail" ? 'pink' : ''}}
                    slot={`id-${row.id}`}
                    key={`id-${row.id}`}
                  >
                    {row.cells['id']}
                  </div>
                ))}
                {newRows.map((row: any) => (
                  <div
                    style={{background: row.cells.outcome == "Fail" ? 'pink' : ''}}
                    slot={`description-${row.id}`}
                    key={`description-${row.id}`}
                  >
                    {row.cells['description']}
                  </div>
                ))}
                {newRows.map((row: any) => (
                  <div
                    style={{background: row.cells.outcome == "Fail" ? 'pink' : ''}}
                    slot={`actualValue-${row.id}`}
                    key={`actualValue-${row.id}`}
                  >
                    {row.cells['actualValue']}
                  </div>
                ))}
                {newRows.map((row: any) => (
                  <div
                    style={{background: row.cells.outcome == "Fail" ? 'pink' : ''}}
                    slot={`expectedValue-${row.id}`}
                    key={`expectedValue-${row.id}`}
                  >
                    {row.cells['expectedValue']}
                  </div>
                ))}
                {newRows.map((row: any) => (
                  <div
                    style={{background: row.cells.outcome == "Fail" ? 'pink' : ''}}
                    slot={`outcome-${row.id}`}
                    key={`outcome-${row.id}`}
                  >
                    {row.cells['outcome']}
                  </div>
                ))}
                </DataGrid>
                <div style={{marginTop: 10, marginBottom: 3}}>
                  <span style={{fontSize: 16}}>
                     {"Please refer to the "}
                    <a rel="noreferrer" target="_blank" href="https://loop.microsoft.com/p/eyJ3Ijp7InUiOiJodHRwczovL21pY3Jvc29mdC5zaGFyZXBvaW50LmNvbS8%2FbmF2PWN6MGxNa1ltWkQxaUlVcGtSbVZwVG01M1pWVkxjbkpPTm5ZMU5YRk9aREIwUWtKbU4wOUtXSFJFYTBSYVN6VTFNVXhTUWpOa1JXdGlMVjlYUWxKUk5IaGpiMWt4VWtrMU5sa21aajB3TVROQ05FSkhWbGRGTTFkSFdrNDBUemRKVWtNeVMwbERRbGxUUzBwTFUxUkZKbU05Sm1ac2RXbGtQVEUlM0QiLCJyIjpmYWxzZX0sInAiOnsidSI6Imh0dHBzOi8vbWljcm9zb2Z0LnNoYXJlcG9pbnQuY29tLzpmbDovci9zaXRlcy8yN2YxMDg0Ny1iZTZmLTRkM2EtYjI2Yi1kMTFhYzcwODM3ZTgvU2hhcmVkJTIwRG9jdW1lbnRzL0xvb3BBcHBEYXRhL1VudGl0bGVkJTIwcGFnZS5mbHVpZD9kPXc2MDA1Mjg5MzY4NTQ0NmNiOGU4ZjU0MjAxNTBiYzhlOCZjc2Y9MSZ3ZWI9MSZuYXY9Y3owbE1rWnphWFJsY3lVeVJqSTNaakV3T0RRM0xXSmxObVl0TkdRellTMWlNalppTFdReE1XRmpOekE0TXpkbE9DWmtQV0loU21SR1pXbE9ibmRsVlV0eWNrNDJkalUxY1U1a01IUkNRbVkzVDBwWWRFUnJSRnBMTlRVeFRGSkNNMlJGYTJJdFgxZENVbEUwZUdOdldURlNTVFUyV1NabVBUQXhNMEkwUWtkV1ZWUkdRVU5YUVZaRVNWcE9SRWsxUkRKVlJVRkxVVmhUU0VrbVl6MGxNa1ltWm14MWFXUTlNU1poUFV4dmIzQkJjSEFtY0QwbE5EQm1iSFZwWkhnbE1rWnNiMjl3TFhCaFoyVXRZMjl1ZEdGcGJtVnlKbmc5SlRkQ0pUSXlkeVV5TWlVelFTVXlNbFF3VWxSVlNIaDBZVmRPZVdJelRuWmFibEYxWXpKb2FHTnRWbmRpTW14MVpFTTFhbUl5TVRoWmFVWkxXa1ZhYkdGVk5YVmtNbFpXVXpOS2VWUnFXakpPVkZaNFZHMVJkMlJGU2tOYWFtUlFVMnhvTUZKSGRFVlhhM014VGxSR1RWVnJTWHBhUlZaeVdXa3habFl3U2xOVlZGSTBXVEk1V2sxV1NrcE9WRnBhWmtSQmVFMHdTVEJSYTJSWFZqQlZlbFl3WkdGVWFsSlFUakJzVTFGNlNreFRWVTVEVjFaT1RGTnJkRlJXUlZVbE0wUWxNaklsTWtNbE1qSnBKVEl5SlROQkpUSXlZelpsT1RObVpXSXRZV001WWkwMFlqWTFMVGc1TnpNdFkyTTVZell6WWpVd01HTTJKVEl5SlRkRSIsInIiOmZhbHNlfSwiaSI6eyJpIjoiYzZlOTNmZWItYWM5Yi00YjY1LTg5NzMtY2M5YzYzYjUwMGM2In19">
                      Troubleshooting Guides
                    </a>
                     {" for additional information."}
                  </span>
                </div>
              </StackItem>
            </Stack>;
}

var newColumns = [
  {
      field: `id`,
      content: `#`,
      sortable: false,
      display: {
          width: '50px',
          maxWidth: '50px'
      }
  },
  {
      field: `description`,
      content: `Description`,
      sortable: false,
      display: {
          width: '300px',
          maxWidth: '600px'
      }
  },
  {
      field: `expectedValue`,
      content: `Expected Value`,
      sortable: false,
      display: {
          width: '100px',
          maxWidth: '100px'
      }
  },
  {
    field: `actualValue`,
    content: `Actual Value`,
    sortable: false,
    display: {
        width: '100px',
        maxWidth: '100px'
    }
  },
  {
    field: `outcome`,
    content: `Outcome`,
    sortable: false,
    display: {
        width: '100px',
        maxWidth: '100px'
    }
  }
]
