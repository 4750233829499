import { HighContrastSelectorWhite, Stack, StackItem } from "@fluentui/react";
import { accordion, accordionItem, pageTitle, button, subway, commandBar, subwayStop, icon, menu, flyInPanel, card, messageBar, createScope, text, textField, select, option, visuallyHidden } from '@harmony/enablers/react';
import React, { useContext, useEffect } from "react";
import '../index.css';
import TableWithFetch from "./TableWithFetch";
import { useParams } from "react-router";
import { tabs, tab, tabPanel } from '@harmony/enablers/react';
import { QuoteTrackerHttpClient } from "../services/QuoteTrackerHttpClient";
import { PageLayoutContext } from "./PageLayout";
import WorkflowTable from "./WorkflowTable";
import LineItemSpecifications from "./LineItemSpecifications";

const scope = createScope({
    reactInstance: React
});

const Tabs = scope.forReact(tabs);
const Tab = scope.forReact(tab);
const TabPanel = scope.forReact(tabPanel);

const PageTitle = scope.forReact(pageTitle);
const Card = scope.forReact(card);
const Button = scope.forReact(button);
const Icon = scope.forReact(icon);
const FlyInPanel = scope.forReact(flyInPanel);
const Menu = scope.forReact(menu);
const TextField = scope.forReact(textField);
const Text = scope.forReact(text);
const VisuallyHidden = scope.forReact(visuallyHidden);
const CommandBar = scope.forReact(commandBar);
const Option = scope.forReact(option);
const Subway = scope.forReact(subway);
const SubwayStop = scope.forReact(subwayStop);
const Accordion = scope.forReact(accordion);
const AccordionItem = scope.forReact(accordionItem);

export function capitalize(str: string) {
    if (str)
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    return null;
}

const expirationDateParse = (dateInput: string) => {
    if (dateInput == "-")
        return dateInput;

    if (dateInput) {
        const date = new Date(dateInput);
        const formattedDate = `${date.toLocaleString('default', { month: 'short' })} ${date.getUTCDate()}, ${date.getUTCFullYear()}, ${date.getUTCHours()}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
    
        const now = new Date();
        const diffTime = Math.abs(date.getTime() - now.getTime());
        if (date < now)
            return `${formattedDate} (Expired)`;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);
        const diffMinutes = Math.floor((diffTime / (1000 * 60)) % 60);
    
        const readableString = `${formattedDate} (in ${diffDays}d ${diffHours}h ${diffMinutes}m)`;
        return readableString;
    }
}

const agreementCreatedDate = (milestones: any) => {
    return milestones?.find((el: any) => el?.name == "DocumentAssemblyDate")?.timeOccured ?? "-";
}

const agreementSigningExpirationDate = (agreement: any) => {
    return agreement?.agreementOptions?.signingExpirationDate ?? agreement?.agreementOptions?.SigningExpirationDate ?? "-";
}

const agreementLastModifiedDate = (agreement: any) => {
    return agreement?.milestones?.reduce((latest: any, milestone: any) => {
        const milestoneDate = new Date(milestone.timeOccured);
        return milestoneDate > latest ? milestoneDate : latest;
    }, new Date(0));
}

const lastModifiedDateParser = (quote: any) => {
    const latestDate = quote?.milestones?.reduce((latest: any, milestone: any) => {
        const milestoneDate = new Date(milestone.timeOccured);
        
        return milestoneDate > latest ? milestoneDate : latest;
    }, new Date(0));

    if (latestDate) {
        const date = new Date(latestDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getUTCDate();
        const monthIndex = date.getUTCMonth();
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();

        return `${monthNames[monthIndex]} ${day}, ${year}, ${hours}:${minutes.toString().padStart(2, '0')}`;
    } else {
        return "-";
    }
}

export const formatDate = (dateInput: string) => {
    if (dateInput == "-")
        return dateInput;

    if (dateInput) {
        const date = new Date(dateInput);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getUTCDate();
        const monthIndex = date.getUTCMonth();
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();

        return `${monthNames[monthIndex]} ${day}, ${year}, ${hours}:${minutes.toString().padStart(2, '0')}`;
    }
}

export default (props: any) => {
    let proposalDetails: any = props?.proposalDetails;
    let agreementDetails: any = props?.agreementDetails;
    let approvalDetails: any = props?.approvalDetails;
    const getChannel = (catalogClaims: any) => {
        if (catalogClaims) {
            const pAudience = catalogClaims[Object.keys(catalogClaims)?.find((claim: any) => claim?.includes('pricingaudience')) ?? ''];
            if (pAudience == undefined)
                return null;

            return pAudience.split('-')[0];
        }
        
        return null;
    }

    const getSegment = (catalogClaims: any) => {
        if (catalogClaims) {
            const pAudience = catalogClaims[Object.keys(catalogClaims)?.find((claim: any) => claim?.includes('pricingaudience')) ?? ''];
            if (pAudience == undefined)
                return null;

            return pAudience.split('-')[1];
        }
        
        return null;
    }

    const statusMap: any = {
        'Draft': 'Draft',
        'Submitted': 'Submitted',
        'Active': 'Active',
        'PurchaseInProgress': 'Purchase in progress',
        'Completed': 'Complete'
    }

    const subwayStops = ['Draft', 'Submitted', 'Active', 'Purchase in progress', 'Complete'];
    const [isMediumOpen, setIsMediumOpen] = React.useState(false);
    const approvalSubmitter = approvalDetails?.players?.find((p: any) => p?.role == "Submitter") ?? null;
    const approvalSubmittedDate = approvalDetails?.milestones?.find((m: any) => m?.name == "Submitted")?.timeOccured ?? null;
    return (
        <Stack style={{marginTop: 10}}>
            <FlyInPanel
                heading="Participant IDs"
                style={{ '--size': 'var(--he-panel-size-medium)' }}
                open={isMediumOpen}
                onHeFlyInAfterHide={() => setIsMediumOpen(false)}
            >
                <Stack>
                    <StackItem>
                    <h5>Bill to</h5>
                        <Stack style={{fontSize: 14}} horizontal>
                            <StackItem>
                                <div style={{fontWeight: 500}}>Account ID:</div>
                                <div style={{marginTop: 10, fontWeight: 500}}>Billing account ID:</div>
                            </StackItem>
                            <StackItem style={{marginLeft: 10}}>
                                {proposalDetails?.proposal?.quote?.header?.billToLegalEntity?.accountId ? <div style={{display: 'flex', alignItems: 'center'}}>
                                    <span key="1">
                                        {proposalDetails?.proposal?.quote?.header?.billToLegalEntity?.accountId}
                                    </span>
                                    <span key="2">
                                        <Icon name="copy" onClick={(e:any) => navigator.clipboard.writeText(proposalDetails?.proposal?.quote?.header?.billToLegalEntity?.accountId)} style={{cursor: 'pointer', color: '#0d6efd', marginLeft: 5}} />
                                    </span>
                                    </div> : <div>{"-"}</div>}
                                {proposalDetails?.proposal?.quote?.header?.billToLegalEntity?.organizationId ? <div style={{marginTop: 10, display: 'flex', alignItems: 'center'}}>
                                    <span key="1">
                                        {proposalDetails?.proposal?.quote?.header?.billToLegalEntity?.organizationId}
                                    </span>
                                    <span key="2">
                                        <Icon name="copy" onClick={(e:any) => navigator.clipboard.writeText(proposalDetails?.proposal?.quote?.header?.billToLegalEntity?.organizationId)} style={{cursor: 'pointer', color: '#0d6efd', marginLeft: 5}} />
                                    </span>
                                    </div> : <div style={{marginTop: 10}}>{"-"}</div>}
                            </StackItem>
                        </Stack>
                    </StackItem>
                    <StackItem>
                    <br />
                    <h5>Sold to</h5>
                    <Stack style={{fontSize: 14}} horizontal>
                            <StackItem style={{marginTop: 10}}>
                                <div style={{fontWeight: 500}}>Account ID:</div>
                                <div style={{marginTop: 10, fontWeight: 500}}>Billing account ID:</div>
                            </StackItem>
                            <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                {proposalDetails?.proposal?.quote?.header?.soldToCustomerLegalEntity?.accountId ? <div style={{display: 'flex', alignItems: 'center'}}>
                                    <span>
                                        {proposalDetails?.proposal?.quote?.header?.soldToCustomerLegalEntity?.accountId}
                                    </span>
                                    <span>
                                        <Icon name="copy" onClick={(e:any) => navigator.clipboard.writeText(proposalDetails?.proposal?.quote?.header?.soldToCustomerLegalEntity?.accountId)} style={{cursor: 'pointer', color: '#0d6efd', marginLeft: 5}} />
                                    </span>
                                    </div> : <div>{"-"}</div>}
                                {proposalDetails?.proposal?.quote?.header?.soldToCustomerLegalEntity?.organizationId ? <div style={{marginTop: 10, display: 'flex', alignItems: 'center'}}>
                                    <span>
                                        {proposalDetails?.proposal?.quote?.header?.soldToCustomerLegalEntity?.organizationId}
                                    </span>
                                    <span>
                                        <Icon name="copy" onClick={(e:any) => navigator.clipboard.writeText(proposalDetails?.proposal?.quote?.header?.soldToCustomerLegalEntity?.organizationId)} style={{cursor: 'pointer', color: '#0d6efd', marginLeft: 5}} />
                                    </span>
                                    </div> : <div style={{marginTop: 10}}>{"-"}</div>}
                            </StackItem>
                        </Stack>
                    </StackItem>
                    <StackItem>
                    <br />
                    <h5>End customer</h5>
                    <Stack style={{fontSize: 14}} horizontal>
                            <StackItem style={{marginTop: 10}}>
                                <div style={{fontWeight: 500}}>Account ID:</div>
                                <div style={{marginTop: 10, fontWeight: 500}}>Billing account ID:</div>
                            </StackItem>
                            <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                {proposalDetails?.proposal?.quote?.header?.endCustomerLegalEntity?.accountId ? <div style={{display: 'flex', alignItems: 'center'}}>
                                    <span>
                                        {proposalDetails?.proposal?.quote?.header?.endCustomerLegalEntity?.accountId}
                                    </span>
                                    <span>
                                        <Icon name="copy" onClick={(e:any) => navigator.clipboard.writeText(proposalDetails?.proposal?.quote?.header?.endCustomerLegalEntity?.accountId)} style={{cursor: 'pointer', color: '#0d6efd', marginLeft: 5}} />
                                    </span>
                                    </div> : <div>{"-"}</div>}
                                {proposalDetails?.proposal?.quote?.header?.endCustomerLegalEntity?.organizationId ? <div style={{marginTop: 10, display: 'flex', alignItems: 'center'}}>
                                    <span>
                                        {proposalDetails?.proposal?.quote?.header?.endCustomerLegalEntity?.organizationId}
                                    </span>
                                    <span>
                                        <Icon name="copy" onClick={(e:any) => navigator.clipboard.writeText(proposalDetails?.proposal?.quote?.header?.endCustomerLegalEntity?.organizationId)} style={{cursor: 'pointer', color: '#0d6efd', marginLeft: 5}} />
                                    </span>
                                    </div> : <div style={{marginTop: 10}}>{"-"}</div>}
                            </StackItem>
                        </Stack>
                    </StackItem>
                </Stack>
            </FlyInPanel>
            <Accordion>
                <AccordionItem expanded style={{marginBottom: 30, border: '1px solid #EDEBE9', borderRadius: '5px',  paddingBottom: 2}}>
                    
                    {/* <span style={{display: 'none'}} slot="expand-icon"></span>
                    <span style={{display: 'none'}} slot="collapse-icon"></span> */}
                    <div slot="heading" style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/infodoc.svg'} style={{ width: '16px', height: '16px', marginRight: 8 }} alt="document with a clock icon" />
                        <span style={{fontFamily: 'Segoe UI semibold', fontSize: '16px'}}>Proposal specifications</span>
                    </div>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                    <Subway style={{marginBottom: 25}} status="progress" label="Publishing Status">
                        {
                            subwayStops.map((stop, index) => {
                                return <SubwayStop current={stop === (statusMap[proposalDetails?.proposal?.quote?.status] ?? 'null')} key={index}>{stop}</SubwayStop>
                            })
                        }
                    </Subway>
                    <Tabs>
                        <Tab>Specifications</Tab>
                        <Tab>In-scope workflows</Tab>
                    
                        <TabPanel>
                            <Stack horizontal>
                            <StackItem grow={2} >
                                <Stack horizontal>
                                    <StackItem style={{marginTop: 10}}>
                                        <span key="1" style={{fontWeight: 500}}>Market:</span><br />
                                        <span key="2"style={{fontWeight: 500}}>Channel:</span><br />
                                        <span key="3" style={{fontWeight: 500}}>Segment:</span><br />
                                        <span key="4" style={{fontWeight: 500}}>Assigned to:</span><br />
                                        <span key="5" style={{fontWeight: 500}}>Transaction model:</span>
                                    </StackItem>
                                    <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                        {proposalDetails?.proposal?.quote?.header?.pricingContext?.market ?? "-"}<br/>
                                        {capitalize(getChannel(proposalDetails?.proposal?.quote?.lineItems[0]?.catalogClaims)) ?? "-"}<br/>
                                        {capitalize(getSegment(proposalDetails?.proposal?.quote?.lineItems[0]?.catalogClaims)) ?? "-"}<br/>
                                        {proposalDetails?.proposal?.quote?.assignedTo ?? "-"}<br/>
                                        {proposalDetails?.proposal?.quote?.header?.transactionModel ?? "-"}<br/>
                                    </StackItem>
                                </Stack>
                            </StackItem>
                            <StackItem  grow={2} >
                            <Stack horizontal>
                                    <StackItem style={{marginTop: 10}}>
                                        <span key="1" style={{fontWeight: 500}}>Opportunity ID:</span><br />
                                        <span key="2" style={{fontWeight: 500}}>Negotiation reason:</span><br />
                                        <span key="3" style={{fontWeight: 500}}>Expiration date:</span><br />
                                        <span key="4" style={{fontWeight: 500}}>Last modified:</span>
                                    </StackItem>
                                    <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                        {proposalDetails?.proposal?.quote?.header?.opportunityId ?? "-"}<br/>
                                        {proposalDetails?.proposal?.quote?.negotiationReason ?? "-"}<br/>
                                        {expirationDateParse(proposalDetails?.proposal?.quote?.header?.expirationDate ?? "-")}<br/>
                                        {lastModifiedDateParser(proposalDetails?.proposal?.quote)}<br/>
                                    </StackItem>
                                </Stack>
                            </StackItem>
                            <StackItem grow={2}>
                            <Stack horizontal>
                                    <StackItem style={{marginTop: 10}}>
                                        <Stack tokens={{childrenGap: '15'}}>
                                        <StackItem>
                                        <div>
                                            <span style={{fontWeight: 500}}>Billing currency:</span><br />
                                            <span style={{fontWeight: 500}}>Estimated deal value:</span><br />
                                            <span style={{fontWeight: 500}}>Total price:</span>
                                        </div>
                                        </StackItem>
                                        <StackItem>
                                            <div>
                                                <Button appearance="link" onClick={(e: any) => setIsMediumOpen(true)}>
                                                    View participant IDs
                                                </Button>
                                            </div>
                                        </StackItem>
                                        </Stack>
                                            
                                        
                                    </StackItem>
                                    <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                        {proposalDetails?.proposal?.quote?.header?.pricingContext?.billingCurrency ?? "-"}<br/>
                                        {proposalDetails?.proposal?.quote?.estimatedDealValue?.toLocaleString() ?? "-"}<br/>
                                        {proposalDetails?.proposal?.quote?.totalPrice?.toLocaleString() ?? "-"}<br/>
                                    </StackItem>
                                </Stack>
                            </StackItem>
                            </Stack>
                        </TabPanel>
                        <TabPanel><WorkflowTable workflows={proposalDetails?.proposal?.quote?.workflow} /></TabPanel>
                    </Tabs>
                    </div>
                </AccordionItem>

                <AccordionItem expanded style={{marginBottom: 30, border: '1px solid #EDEBE9', borderRadius: '5px',  paddingBottom: 2}}>
                    
                    {/* <span style={{display: 'none'}} slot="expand-icon"></span>
                    <span style={{display: 'none'}} slot="collapse-icon"></span> */}
                    <div slot="heading" style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon name="accept" style={{marginRight: 8}} />
                        <span style={{fontFamily: 'Segoe UI semibold', fontSize: '16px'}}>Approval specifications</span>
                    </div>

                    <div style={{marginLeft: 10, marginRight: 10}}>
                    <Stack horizontal>
                        <StackItem grow={2} >
                            <Stack horizontal>
                                <StackItem style={{marginTop: 10}}>
                                    <span style={{fontWeight: 500}}>Approval ID:</span><br />
                                    <span style={{fontWeight: 500}}>Approval type:</span><br />
                                    <span style={{fontWeight: 500}}>Status:</span>
                                </StackItem>
                                <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                    {approvalDetails?.id ?? '-'}<br/>
                                    {approvalDetails?.workflow ?? '-'}<br/>
                                    {capitalize(approvalDetails?.status ?? '-')}
                                </StackItem>
                            </Stack>
                        </StackItem>
                        <StackItem  grow={2} >
                        <Stack horizontal>
                                <StackItem style={{marginTop: 10}}>
                                    <span style={{fontWeight: 500}}>Submitter:</span><br />
                                    <span style={{fontWeight: 500}}>Submitted date:</span>
                                </StackItem>
                                <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                    {approvalSubmitter?.email ?? '-'}<br/>
                                    {formatDate(approvalSubmittedDate ?? '-')}
                                </StackItem>
                            </Stack>
                        </StackItem>
                        <StackItem grow={2}>
                        <Stack horizontal>
                                <StackItem style={{marginTop: 10}}>
                                    <Stack tokens={{childrenGap: '15'}}>
                                    <StackItem>
                                    <div>
                                        <span style={{fontWeight: 500}}>Version:</span><br />
                                        <span style={{fontWeight: 500}}>RulesVersion:</span>
                                    </div>
                                    </StackItem>
                                    </Stack>
                                        
                                    
                                </StackItem>
                                <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                    {approvalDetails?.version ?? '-'}<br/>
                                    {approvalDetails?.ruleVersion ?? '-'}
                                </StackItem>
                            </Stack>
                        </StackItem>
                        </Stack>
                    </div>
                </AccordionItem>

                <AccordionItem expanded style={{marginBottom: 30, border: '1px solid #EDEBE9', borderRadius: '5px',  paddingBottom: 2}}>
                    
                    {/* <span style={{display: 'none'}} slot="expand-icon"></span>
                    <span style={{display: 'none'}} slot="collapse-icon"></span> */}
                    <div slot="heading" style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/f029.svg'} style={{ width: '16px', height: '16px', marginRight: 8 }} alt="document with a clock icon" />
                        <span style={{fontFamily: 'Segoe UI semibold', fontSize: '16px'}}>Agreement specifications</span>
                    </div>

                    <div style={{marginLeft: 10, marginRight: 10}}>
                    <Stack horizontal>
                        <StackItem grow={2} >
                            <Stack horizontal>
                                <StackItem style={{marginTop: 10}}>
                                    <span style={{fontWeight: 500}}>Agreement ID:</span><br />
                                    <span style={{fontWeight: 500}}>Acceptance mode:</span><br />
                                    <span style={{fontWeight: 500}}>Status:</span><br />
                                    <span style={{fontWeight: 500}}>Language:</span>
                                </StackItem>
                                <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                    {agreementDetails?.id ?? '-'}<br/>
                                    {agreementDetails?.acceptanceMode ?? '-'}<br/>
                                    {capitalize(agreementDetails?.status) ?? '-'}<br />
                                    {agreementDetails?.languageLocale ?? '-'}<br />
                                </StackItem>
                            </Stack>
                        </StackItem>
                        <StackItem  grow={2} >
                        <Stack horizontal>
                                <StackItem style={{marginTop: 10}}>
                                    <span style={{fontWeight: 500}}>Created date:</span><br />
                                    <span style={{fontWeight: 500}}>Modified date:</span><br />
                                    <span style={{fontWeight: 500}}>Signing expiration date:</span>
                                </StackItem>
                                <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                    {formatDate(agreementDetails?.createdDate ?? '-')}<br/>
                                    {formatDate(agreementDetails?.modifiedDate ?? '-')}<br />
                                    {formatDate(agreementSigningExpirationDate(agreementDetails))}<br />
                                </StackItem>
                            </Stack>
                        </StackItem>
                        <StackItem grow={2}>
                        <Stack horizontal>
                                <StackItem style={{marginTop: 10}}>
                                    <Stack tokens={{childrenGap: '15'}}>
                                    <StackItem>
                                    <div>
                                        <span style={{fontWeight: 500}}>Template name:</span><br />
                                        <span style={{fontWeight: 500}}>Template ID:</span>
                                    </div>
                                    </StackItem>
                                    </Stack>
                                        
                                    
                                </StackItem>
                                <StackItem style={{marginLeft: 10, marginTop: 10}}>
                                    {agreementDetails?.template?.name ?? '-'}<br/>
                                    {agreementDetails?.template?.id ?? '-'}
                                </StackItem>
                            </Stack>
                        </StackItem>
                        </Stack>
                    </div>
                </AccordionItem>

                <AccordionItem expanded style={{marginBottom: 30, border: '1px solid #EDEBE9', borderRadius: '5px',  paddingBottom: 2}}>
                    
                    {/* <span style={{display: 'none'}} slot="expand-icon"></span>
                    <span style={{display: 'none'}} slot="collapse-icon"></span> */}
                    <div slot="heading" style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/box.svg'} style={{ width: '16px', height: '16px', marginRight: 8 }} alt="document with a clock icon" />
                        <span style={{fontFamily: 'Segoe UI semibold', fontSize: '16px'}}>Line-item specifications</span>
                    </div>

                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <LineItemSpecifications lineItems={proposalDetails?.proposal?.quote?.lineItems} />
                    </div>
                </AccordionItem>
            
            </Accordion>
        </Stack>
    )
}