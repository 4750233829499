export const Legend = () => {
    return <div dangerouslySetInnerHTML={{__html: `<!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Legend Example</title>
    <style>
      .legend {
        margin: 5px;
        //margin-bottom: 10px;
        //max-width: 25%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        border: 0px solid #ccc;
        padding: 10px;
        //background-color: #f8f8f8;
        font-family: Arial, sans-serif;
        font-size: 12px;
      }
    
      .legend-item {
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    
      .legend-color-box {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    
      .legend-label {
        white-space: nowrap;
      }
    
      .error-box {
        background-color: #ff4d4d;
      }
    
      .warning-box {
        background-color: #0078d4;
      }
    
      .success-box {
        background-color: #49c5b1;
      }

      .active-box {
        background-color: #cccc00;
      }
    </style>
    </head>
    <body>
      <div class="legend">
        <div class="legend-item">
          <div class="legend-label"><b>Legend: </b></div>
        </div>
        <div class="legend-item">
          <div class="legend-color-box error-box"></div>
          <div class="legend-label">Expired/Rejected</div>
        </div>
        <div class="legend-item">
          <div class="legend-color-box warning-box"></div>
          <div class="legend-label">Draft</div>
        </div>
        <div class="legend-item">
          <div class="legend-color-box active-box"></div>
          <div class="legend-label">Submitted/Active/PurchaseInProgress</div>
        </div>
        <div class="legend-item">
          <div class="legend-color-box success-box"></div>
          <div class="legend-label">Completed</div>
        </div>
      </div>
    </body>
    </html>       
    `}} />;
}