import { card, dataGrid, createScope } from '@harmony/enablers/react';
import React from 'react';
import { formatDate } from './OverviewTab';

const scope = createScope({
    reactInstance: React
})

const Card = scope.forReact(card);
const DataGrid = scope.forReact(dataGrid);

export function capitalize(str: string) {
    if (str)
        return str.charAt(0).toUpperCase() + str.slice(1);
}

const diffDate = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
        const startDate2 = new Date(startDate);
        const endDate2 = new Date(endDate);

        const diffTime = Math.abs(endDate2.getTime() - startDate2.getTime());
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);
        const diffMinutes = Math.floor((diffTime / (1000 * 60)) % 60);

        const readableString = `${diffDays}d ${diffHours}h ${diffMinutes}m`;
        return readableString;
    }
}


export default (param: any) => {
    let newTopRows = [
        {id: "InScope", expanded: true, cells: {name: "In-scope workflows"}},
    ];

    let anyOutScope = false;
    var newBottomRows = Object.entries(param.workflows ?? {})?.map((el: any) => {
        let key = capitalize(el[0]) ?? "-";
        let val = el[1];

        if (val?.scope == "OutOfScope" && !anyOutScope)
            anyOutScope = true;

        let additional = {}
        if (val?.scope == "InScope")
        {
            additional = {
                duration: diffDate(val?.startDate, val?.endDate),
                dates: `${formatDate(val?.startDate)} - ${formatDate(val?.endDate)}`
            };
        }

        const row = {
            id: key,
            parentId: val?.scope == "OutOfScope" ? "OutOfScope" : "InScope",
            cells: {name: key, sequence: val?.sequence, status: val?.status, ...additional}
        }
        return row;
    });

    if (anyOutScope)
        newTopRows = [newTopRows[0], {id: "OutOfScope", expanded: false, cells: {name: "Out-of-scope workflows"}}]
    else {
        newTopRows = []
        newBottomRows.forEach((el: any) => el.parentId = null)
    }
      
    let newRows = [...newTopRows, ...newBottomRows];

    return (<DataGrid rows={newRows ?? []} columns={newColumns}>

    </DataGrid>)
};

var newColumns = [
    {
        field: `name`,
        content: `Workflow type`,
        sortable: false,
        display: {
            width: '500px',
            maxWidth: '500px'
        }
    },
    {
        field: `sequence`,
        content: `Sequence`,
        sortable: false,
        display: {
            width: '75px',
            maxWidth: '75px'
        }
    },
    {
        field: `status`,
        content: `Status`,
        sortable: false,
        display: {
            width: '100px',
            maxWidth: '100px'
        }
    },
    {
        field: `duration`,
        content: `Duration`,
        sortable: false,
        display: {
            width: '100px',
            maxWidth: '100px'
        }
    },
    {
        field: `dates`,
        content: `Dates`,
        sortable: false,
        display: {
            width: '300px',
            maxWidth: '300px'
        }
    },
];
