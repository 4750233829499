import {
    createScope,
    dataGrid
  } from '@harmony/enablers/react';
import React from 'react';
import { Stack, Link } from "@fluentui/react";

const scope = createScope({
    reactInstance: React
});

const DataGrid = scope.forReact(dataGrid);

const accessPackages = {
    "Reader Access": {
      url: "https://myaccess.microsoft.com/@microsoft.onmicrosoft.com#/access-packages/62f7606c-d4b9-4456-834e-79036e97f581",
      desc: "Provides read access to Proposal Insights."
    },
    "Notification Access": {
      url: "https://myaccess.microsoft.com/@microsoft.onmicrosoft.com#/access-packages/fdbdff00-b39f-4ed3-8315-2cbbdcfca024",
      desc: "Subscribe to Proposal Insights Daily Digest."
    }
  };
  
  const accessPackageColumns = [
    { key: "name", content: "Name", name: "Name", field: "name", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "desc", content: "Description", name: "Description", field: "desc", minWidth: 100, maxWidth: 200, isResizable: true }
  ];
  
  var keys = Object.keys(accessPackages);
  const accessPackageRows = keys.map((el: string, index: any) => {
    return {
      id: el,
      cells: {name: el, desc: accessPackages[el as keyof typeof accessPackages].desc, url: accessPackages[el as keyof typeof accessPackages].url}
    }
  });

export default () => {
    return (<Stack style={{margin: "5vh 3vh", width: "50%"}}>
    <p>Outlined are the access packages for Proposal Insights.</p>
    <DataGrid rows={accessPackageRows} columns={accessPackageColumns} label="Access Packages">
      {
        accessPackageRows.map((row: any, idx: any) => {
          return <div slot={`name-${row.id}`} key={`name-${row.id}`}>
            <Link href={row.cells.url} target='_blank'>{row.cells.name}</Link>
          </div>
        })
      }
    </DataGrid>
  </Stack>);
}