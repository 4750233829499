import { Stack } from '@fluentui/react';
import { skeleton, createScope } from '@harmony/enablers/react';
import React from 'react';

const scope = createScope({
    reactInstance: React
});
  
const Skeleton = scope.forReact(skeleton);

export default () => {
    return (<div style={{ boxSizing: 'border-box' }}>
            <Skeleton
                style={{
                
                borderRadius: '4px',
                width: '100%',
                height: '30vw',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />

        {/* <Skeleton style={{ width: '50px', height: '50px' }} shape="circle" shimmer />
        <Skeleton
            style={{ borderRadius: '4px', marginTop: '10px', height: '10px' }}
            shape="rect"
            shimmer
        />
        <Skeleton
            style={{ borderRadius: '4px', marginTop: '10px', height: '10px' }}
            shape="rect"
            shimmer
        />
        <Skeleton
            style={{ borderRadius: '4px', marginTop: '10px', height: '10px' }}
            shape="rect"
            shimmer
        />
        <Skeleton
            style={{
            borderRadius: '4px',
            width: '75px',
            height: '30px',
            marginTop: '20px',
            marginBottom: '10px',
            }}
            shape="rect"
            shimmer
        /> */}

    </div>)
}