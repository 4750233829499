import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ViewAllProposals } from './components/ViewAllProposals';
import { PageLayout } from './components/PageLayout';
import ProposalDetails from './components/ProposalDetails';
import MyTrackedProposals from './components/MyTrackedProposals';
import { aadConfig } from './aadTokenConfig';
import BusinessTrackedProposals from './components/BusinessTrackedProposals';
import mermaid from 'mermaid';

const msalInstance = new PublicClientApplication(aadConfig);

mermaid.initialize({
  startOnLoad: true
});

ReactDOM.render(
  <React.StrictMode>
    <div style={{ height: "100%" }}>
          <MsalProvider instance={msalInstance}>
            <Router>
              <PageLayout>
                <Routes>
                  <Route
                    path="/"
                    element={<MyTrackedProposals />}
                  />
                  <Route
                    path="/business-tracked-proposals"
                    element={<BusinessTrackedProposals />}
                  />
                  <Route
                    path="/view-all-proposals"
                    element={<ViewAllProposals />}
                  />
                  <Route
                    path="/proposal-details/:id"
                    element={<ProposalDetails />}
                  />
                </Routes>
              </PageLayout>
            </Router>
          </MsalProvider>
      </div>
      
  </React.StrictMode>,
  document.getElementById("root")
);
