import { Panel, PrimaryButton, ProgressIndicator, Stack, StackItem, TextField } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { GenericTrackerPage } from "./GenericTrackerPage";
import { PageLayoutContext } from './PageLayout';
import { useBoolean } from '@fluentui/react-hooks';
import { createScope, progressRing } from '@harmony/enablers/react';

const scope = createScope({
    reactInstance: React
});
  
const ProgressRing = scope.forReact(progressRing);

export default () => {
    const { myTrackerData, RemoveQuoteFromTrackerList, AddNewQuoteToTrack, myTrackerFetching, myTrackerList, setCurrentErrorMessageWithTimeout, setCurrentWarningMessageWithTimeout, setCurrentSuccessMessageWithTimeout, accessToken, generateToken } = useContext(PageLayoutContext) as any;
    
    const [isAddQuotePanelOpen, { setTrue: openAddQuotePanel, setFalse: dismissAddQuotePanel }] = useBoolean(false);
    const [addQuoteButtonDisabled, setAddQuoteButtonDisabled] = useState<boolean>(true);

    const [currentAddQuoteTextInput, setCurrentAddQuoteTextInput] = useState<string>('');
    
    useEffect(() => {
        setAddQuoteButtonDisabled(myTrackerFetching)
    }, [myTrackerFetching]);

    return (
        <div style={{ position: 'relative' }}>
        {myTrackerFetching && 
            <div style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                right: 0, 
                bottom: 0, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
                zIndex: 1 // ensure the overlay is on top
            }}>
                <ProgressRing indeterminate={true} />
            </div>
        }
        <div style={{ opacity: myTrackerFetching ? 0.5 : 1 }}> {/* Diminished view when fetching data */}
            <div style={{marginBottom:20, textAlign:'right'}}>
                <PrimaryButton text="Track New Quotes"  onClick={openAddQuotePanel} />
            </div>
            <GenericTrackerPage removeQuoteFromList={RemoveQuoteFromTrackerList} listOfQuotes={myTrackerList} />
        </div>
            <Panel
            headerText="Track New Quotes"
            isOpen={isAddQuotePanelOpen}
            onDismiss={dismissAddQuotePanel}
            // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
            closeButtonAriaLabel="Close"
          >
            <Stack>
              <StackItem>
                <TextField label="Enter Quote Ids (comma separated):" onChange={(e) => setCurrentAddQuoteTextInput(e.currentTarget.value)} value={currentAddQuoteTextInput} />
              </StackItem>
              <StackItem style={{justifyContent: 'end', display: 'flex'}}>
                <PrimaryButton text={"Track New Quotes"} style={{marginTop: 15}} disabled={addQuoteButtonDisabled} onClick={(e) => AddNewQuoteToTrack(
                            currentAddQuoteTextInput,
                            dismissAddQuotePanel)} />
              </StackItem>
            </Stack>
          </Panel>
        </div>
    )
}
