export function getEndpointfromHostName() {
  switch (window.location.hostname) {
    case "localhost":
      return "localhost:44393";

    case "proposaltrackerui-webapp-int-staging.azurewebsites.net":
      return "proposaltracker-webapp-wus-global-int-staging.azurewebsites.net";
    
    case "proposaltrackerui-webapp-eus-global-int-staging.azurewebsites.net":
      return "proposaltracker-webapp-eus-global-int-staging.azurewebsites.net";

    case "proposalinsights.int.l2o.microsoft.com":
    case "proposaltrackerui-webapp-eus-global-int.azurewebsites.net":
    case "proposaltrackerui-webapp-int.azurewebsites.net":
      return "proposaltracker.int.l2o.microsoft.com";
    
    case "proposalinsights.l2o.microsoft.com":
    case "proposaltrackerui-webapp-eus-global-prod.azurewebsites.net":
    case "proposaltrackerui-webapp-prod.azurewebsites.net":
      return "proposaltracker.l2o.microsoft.com";

    case "proposaltrackerui-webapp-prod-staging.azurewebsites.net":
      return "proposaltracker-webapp-wus-global-prod-staging.azurewebsites.net";

    case "proposaltrackerui-webapp-eus-global-prod-staging.azurewebsites.net":
      return "proposaltracker-webapp-eus-global-prod-staging.azurewebsites.net";

    default:
      return "proposaltracker.int.l2o.microsoft.com";
  }
}

export function getClientIdFromHostname() {
  switch (window.location.hostname) {
    case "localhost":
    case "proposaltrackerui-webapp-eus-global-int-staging.azurewebsites.net":
    case "proposaltrackerui-webapp-eus-global-int.azurewebsites.net":
    case "proposalinsights.int.l2o.microsoft.com":
    case "proposaltrackerui-webapp-int-staging.azurewebsites.net":
    case "proposaltrackerui-webapp-int.azurewebsites.net":
      return "f84fa335-6b1e-4fc1-a613-47599a07bc64";
    
    case "proposalinsights.l2o.microsoft.com":
    case "proposaltrackerui-webapp-eus-global-prod-staging.azurewebsites.net":
    case "proposaltrackerui-webapp-eus-global-prod.azurewebsites.net":
    case "proposaltrackerui-webapp-prod-staging.azurewebsites.net":
    case "proposaltrackerui-webapp-prod.azurewebsites.net":
      return "1a25f553-3ecf-4e99-bfd2-801483c05bb2";
    
    default:
      return "f84fa335-6b1e-4fc1-a613-47599a07bc64";
  }
}

export function getServiceIdFromHostname() {
  switch (window.location.hostname) {
    case "localhost":
    case "proposalinsights.int.l2o.microsoft.com":
    case "proposaltrackerui-webapp-eus-global-int-staging.azurewebsites.net":
    case "proposaltrackerui-webapp-eus-global-int.azurewebsites.net":
    case "proposaltrackerui-webapp-int-staging.azurewebsites.net":
    case "proposaltrackerui-webapp-int.azurewebsites.net":
      return "0ae505e2-3e1b-43ac-a719-4d7279151a24";
    
    case "proposalinsights.l2o.microsoft.com":
    case "proposaltrackerui-webapp-eus-global-prod-staging.azurewebsites.net":
    case "proposaltrackerui-webapp-eus-global-prod.azurewebsites.net":
    case "proposaltrackerui-webapp-prod-staging.azurewebsites.net":
    case "proposaltrackerui-webapp-prod.azurewebsites.net":
      return "7a11017e-75f3-4576-a259-c3727fb552a1";

    default:
      return "0ae505e2-3e1b-43ac-a719-4d7279151a24";
  }
}
