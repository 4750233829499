import { Stack } from '@fluentui/react';
import { skeleton, createScope } from '@harmony/enablers/react';
import React from 'react';

const scope = createScope({
    reactInstance: React
});
  
const Skeleton = scope.forReact(skeleton);

export default () => {
    return (<div style={{ boxSizing: 'border-box' }}>
        <Stack horizontal style={{marginTop: 10}}>
            <Skeleton
                style={{
                borderRadius: '4px',
                width: '75px',
                height: '30px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                marginLeft: '5px',
                borderRadius: '4px',
                width: '5px',
                height: '30px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                marginLeft: '5px',
                borderRadius: '4px',
                width: '75px',
                height: '30px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                marginLeft: '5px',
                borderRadius: '4px',
                width: '50px',
                height: '25px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
        </Stack>
        <Stack horizontal style={{marginTop: 35}}>
            <Skeleton
                style={{
                borderRadius: '4px',
                width: '75px',
                height: '20px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                marginLeft: '5px',
                borderRadius: '4px',
                width: '75px',
                height: '20px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                marginLeft: '5px',
                borderRadius: '4px',
                width: '75px',
                height: '20px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                marginLeft: '5px',
                borderRadius: '4px',
                width: '75px',
                height: '20px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                marginLeft: '5px',
                borderRadius: '4px',
                width: '75px',
                height: '20px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                marginLeft: '5px',
                borderRadius: '4px',
                width: '75px',
                height: '20px',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
        </Stack>
        <Stack style={{marginTop: 10}}>
            <Skeleton
                style={{
                
                borderRadius: '4px',
                width: '100%',
                height: '25vw',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                
                borderRadius: '4px',
                width: '100%',
                height: '10vw',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                
                borderRadius: '4px',
                width: '100%',
                height: '20vw',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{
                
                borderRadius: '4px',
                width: '100%',
                height: '30vw',
                marginBottom: '10px',
                }}
                shape="rect"
                shimmer
            />
        </Stack>

        {/* <Skeleton style={{ width: '50px', height: '50px' }} shape="circle" shimmer />
        <Skeleton
            style={{ borderRadius: '4px', marginTop: '10px', height: '10px' }}
            shape="rect"
            shimmer
        />
        <Skeleton
            style={{ borderRadius: '4px', marginTop: '10px', height: '10px' }}
            shape="rect"
            shimmer
        />
        <Skeleton
            style={{ borderRadius: '4px', marginTop: '10px', height: '10px' }}
            shape="rect"
            shimmer
        />
        <Skeleton
            style={{
            borderRadius: '4px',
            width: '75px',
            height: '30px',
            marginTop: '20px',
            marginBottom: '10px',
            }}
            shape="rect"
            shimmer
        /> */}

    </div>)
}