import { getClientIdFromHostname, getServiceIdFromHostname } from "./EnvironmentConfig";

export const aadConfig = {
  auth: {
    clientId: getClientIdFromHostname(),
    authority:
      // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
    extraQueryParameters: { "safe_rollout": "apply:68973ccc-0a07-41ef-91d9-f758af2fe58a" },
    tokenQueryParameters: { "safe_rollout": "apply:68973ccc-0a07-41ef-91d9-f758af2fe58a" },
    state: "safe_rollout=apply:68973ccc-0a07-41ef-91d9-f758af2fe58a"
  },
  cache: {
    // This configures where your cache will be stored
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  },
};
export const accessRequest = {
  scopes: [
    `api://${getServiceIdFromHostname()}/Read.All`
  ],
  extraQueryParameters: { "safe_rollout": "apply:68973ccc-0a07-41ef-91d9-f758af2fe58a" },
  tokenQueryParameters: { "safe_rollout": "apply:68973ccc-0a07-41ef-91d9-f758af2fe58a" },
  state: "safe_rollout=apply:68973ccc-0a07-41ef-91d9-f758af2fe58a"
};
