import { getEndpointfromHostName } from "../EnvironmentConfig";

export interface IQuoteTrackerHttpClient
{
    sendCall (relativeUrl: string, options: any, callbackFunction: any): void;

    generateCallOptions (accessToken: string, bodyJson: string, method: string, etag?: any, signal?:  any): any;
}

export class QuoteTrackerHttpClient implements IQuoteTrackerHttpClient
{
    private _errorMessageHandler: any;
    private _progressHandler: any;
    private _generateToken: any;
    private _etagSetter: any;

    constructor(errorMessageHandler: any, progressHandler: any, generateToken: any, etagSetter: any)
    {
        this._errorMessageHandler = errorMessageHandler;
        this._progressHandler = progressHandler;
        this._generateToken = generateToken;
        this._etagSetter = etagSetter
    }

    public sendCall = (relativeUrl: string, options: any, callbackFunction: any): void => 
    {
        this._progressHandler(true);
        this._generateToken();
        
        var url = "https://" + getEndpointfromHostName() + relativeUrl;
        fetch(
            url,
            options
        )
        .then(r => {
            if (r.headers.get("Etag") !== (null || undefined) && relativeUrl.includes("quotetracker"))
            {
                this._etagSetter(r.headers.get("Etag"))
            }

            var emptyArray: [] = [];
            return ((r as any).status === 204 || (r as any).status === 400) && relativeUrl.includes("quotetracker") ? new Promise<any>((resolve, reject) => resolve(emptyArray)) : r.json();
        })
        .then((resp: any) => {
            callbackFunction(resp)
        })
        .catch(e => {
            if ((e as Error).message !== "No Trackers found" && !relativeUrl.includes("quotetracker?createdBy"))
            {
                this._errorMessageHandler((e as Error).message + ` - url that failed: ${url}`)
            }
        })
        .finally(() => {
            if (!options?.signal?.aborted) {
                this._progressHandler(false)
            }
        });
    }

    public sendCallWithNoCallback = (relativeUrl: string, options: any): Promise<any> => {
        this._progressHandler(true);
        this._generateToken()
        var url = "https://" + getEndpointfromHostName() + relativeUrl;
        return fetch(
            url,
            options
        );
    }

    public generateCallOptions = (accessToken: string, bodyJson: string, method: string, etag?: string, signal?: any): any =>
    {   
        const bearer = `Bearer ${accessToken}`;
        const headerslist = new Headers();
        headerslist.append("Authorization", bearer);
        headerslist.append("Access-Control-Allow-Origin", "*");
        headerslist.append("Access-Control-Allow-Headers", "content-type");
        headerslist.append("Content-Type", "application/json");
        if (etag != (undefined || null))
        {
            headerslist.append("If-Match", etag);   
        }

        const options = {
            method: method,
            headers: headerslist,
            body: method == "GET" ? null : bodyJson,
            signal: signal ?? null
        } as any;

        return options;
    }
};
